// MatezaTooltip.js

import React from 'react';
import { Tooltip, OverlayTrigger, Button } from 'react-bootstrap';

const MatezaTooltip = ({ language }) => {
  const renderTooltip = (props) => (
    <Tooltip className="custom-tooltip" {...props}>
      <div>
        <p>
          {language === 'NL'
            ? 'Mateza is opgericht door Thibault Van de Sompele (23), een jonge programmeur die het monopolie in reservatiesystemen wilt doorbreken.'
            : language === 'FR'
              ? 'Mateza a été fondée par Thibault Van de Sompele (23 ans), un jeune programmeur qui souhaite briser le monopole des systèmes de réservation.'
              : 'Mateza was founded by Thibault Van de Sompele (23), a young programmer who wants to break the monopoly in reservation systems.'
          }
        </p>
        <div className="tooltip-buttons">
          <Button variant="dark" size="sm" href="https://mateza.be" target="_blank">
            {language === 'NL' ? 'Ontdek meer' : language === 'FR' ? 'Découvrir plus' : 'Discover more'}
          </Button>
        </div>
      </div>
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 3000 }}
      overlay={renderTooltip}
    >
      <span className="info-icon"> ⓘ</span>
    </OverlayTrigger>
  );
};

export default MatezaTooltip;
