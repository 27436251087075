import React from 'react';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import '../App.css';
import './css/Failed.css';

const Failed = () => {
  const { language } = useParams();

  return (
    <Container className="mt-10">
      <div className="fullscreen-bg"></div>
      <div className="black-overlay"></div>
      <Row className="justify-content-md-center">
        <Col xs={12} sm={12} md={12} lg={8} xl={8} className="text-white">
        <Alert className="alert" variant="warning">
          {language === 'NL'
            ? 'Controleer uw email-adres en probeer opnieuw.'
            : language === 'FR'
              ? 'Vérifiez votre adresse e-mail et réessayez.'
              : 'Check your email address and try again.'}
        </Alert>
        </Col>
      </Row>
    </Container>
  );
};

export default Failed;
