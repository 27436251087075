// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Reservation from './pages/Reservation';
import Success from './pages/Success';
import Failed from './pages/Failed';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Reservation />} />
        <Route path="/failed/:language" element={<Failed />} />
        <Route path="/success/:language" element={<Success />} />
      </Routes>
    </Router>
  );
}

export default App;
