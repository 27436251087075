import React, { useState, useEffect } from 'react';

const LoadingMessages = ({ language }) => {
  const messages = {
    EN: [
      "Choosing your table...",
      "Folding the napkins...",
      "Polishing the cutlery...",
      "Setting the glasses...",
      "Lighting the candles..."
    ],
    NL: [
      "Bezig met uw tafel te kiezen.",
      "De servetten worden gevouwen...",
      "Uw bestek wordt gepolijst.",
      "De glazen worden op hun plaats gezet.",
      "De kaarsen worden aangestoken."
    ],
    FR: [
      "Choix de votre table...",
      "Pliage des serviettes...",
      "Polissage des couverts...",
      "Mise en place des verres...",
      "Allumage des bougies..."
    ]
  };

  const [currentMessage, setCurrentMessage] = useState(0);

  useEffect(() => {
    const selectedMessages = messages[language] || messages['EN'];
    const interval = setInterval(() => {
      setCurrentMessage((prevMessage) => (prevMessage + 1) % selectedMessages.length);
    }, 1750);

    return () => clearInterval(interval);
  }, [language]);

  const selectedMessages = messages[language] || messages['EN'];

  return (
    <div className="loading-message">
      {selectedMessages[currentMessage]}
    </div>
  );
};

export default LoadingMessages;
