import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, Tooltip, OverlayTrigger, Button, Alert } from 'react-bootstrap';
import MatezaTooltip from '../components/MatezaTooltip';
import Select from 'react-select';
import Calendar from '../components/Calendar';
import Timeslot from '../components/Timeslot';
import ReservationModal from '../components/ReservationModal';
import ExplanationModal from '../components/ExplanationModal';
import { registerLocale } from 'react-datepicker';
import nl from 'date-fns/locale/nl';
import fr from 'date-fns/locale/fr';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { slotToTime, slotToQuarterTime } from '../utils/converters';
import './css/Reservation.css';
import LoadingPage from './LoadingPage';

registerLocale('nl', nl);
registerLocale('fr', fr);

const languageOptions = [
  { value: 'NL', label: 'NL' },
  { value: 'EN', label: 'EN' },
  { value: 'FR', label: 'FR' }
];

const Reservation = () => {
  const [language, setLanguage] = useState('NL');
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [slotData, setSlotData] = useState({});
  const [cachedSlotData, setCachedSlotData] = useState({});
  const [guests, setGuests] = useState(2);
  const [showModal, setShowModal] = useState(false);
  const [showExplanationModal, setShowExplanationModal] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [labels, setLabels] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [selectedLabelId, setSelectedLabelId] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState('');
  const [extraInfo, setExtraInfo] = useState('');
  const [isQuarterSlot, setIsQuarterSlot] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [visibleNotifications, setVisibleNotifications] = useState([]);
  const navigate = useNavigate();
  const guestsRef = useRef(null);
  const [isFetchingSlots, setIsFetchingSlots] = useState(false);
  const [agreedTerms, setAgreedTerms] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchNotifications = async () => {
    try {
      const response = await fetch(
        `https://booking-system-euro-3c75b18a7c36.herokuapp.com/restaurants/notifications-client/${window.restaurantNumber}/`
      );
      const data = await response.json();
      setNotifications(data);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const decodeRules = (rules) => {
    const monthNames = ["", "Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"];
    const dayNames = ["", "Ma", "Di", "Wo", "Do", "Vr", "Za", "Zo"];
    
    let decodedRules = {
      months: [],
      weekDays: [],
      specificDays: [],
    };

    if (rules) {
      const specificMonthMatches = rules.match(/_m(10|11|12)/g);
      const monthMatch = rules.match(/_m([1-9]|1[0-2])/);
        if (specificMonthMatches) {
        decodedRules.months.push(...specificMonthMatches.map(month => parseInt(month.slice(2), 10)));
        } else if (monthMatch) {
        decodedRules.months.push(parseInt(monthMatch[1], 10));
      }

      const dayMatches = rules.match(/d[1-7]/g);
      if (dayMatches) {
        decodedRules.weekDays.push(...dayMatches.map(day => parseInt(day.slice(1), 10)));
      }

      const dayNumberMatch = rules.match(/_day(\d{1,2})/);
      if (dayNumberMatch) {
        decodedRules.specificDays.push(parseInt(dayNumberMatch[1], 10));
      }
    }

    return decodedRules;
  };

  const shouldShowNotification = (notification, selectedDate) => {
    const { months, weekDays, specificDays } = decodeRules(notification.rules);
    const selectedMonth = selectedDate.getMonth() + 1;
    const selectedWeekDay = selectedDate.getDay();
    const selectedDayNumber = selectedDate.getDate();
  
    const monthMatches = months.length === 0 || months.includes(selectedMonth);
    const weekDayMatches = weekDays.length === 0 || weekDays.includes(selectedWeekDay);
    const dayNumberMatches = specificDays.length === 0 || specificDays.includes(selectedDayNumber);
    const shouldShow = monthMatches && weekDayMatches && dayNumberMatches;
  
    return shouldShow;
  };
  

  useEffect(() => {
    if (selectedDate) {
      const filteredNotifications = notifications.filter(notification =>
        shouldShowNotification(notification, selectedDate)
      );
      setVisibleNotifications(filteredNotifications);
    }
  }, [selectedDate, notifications]);

  const fetchSlotData = async (year, month, labelId) => {
    const cacheKey = `${year}-${month}-${labelId}`;
    if (cachedSlotData[cacheKey]) {
      setSlotData(cachedSlotData[cacheKey]);
      return;
    }

    if (isFetchingSlots) return;
    setIsFetchingSlots(true);

    try {
      const today = new Date();
      const currentYear = today.getFullYear();
      const currentMonth = today.getMonth() + 1;
      const startDay = (year === currentYear && month === currentMonth) ? String(today.getDate()).padStart(2, '0') : '01';
      const startOfMonth = `${year}-${String(month).padStart(2, '0')}-${startDay}`;
      const endOfMonth = `${year}-${String(month).padStart(2, '0')}-${new Date(year, month, 0).getDate()}`;

      let data = {};

      if (window.eventPage) {
        const allDates = window.allDates || [];
        const promises = allDates.map(date =>
          fetch(`${window.baseDomain}restaurants/slot/${window.restaurantNumber}/${date}/${labelId}`)
            .then(response => response.json())
            .then(slotData => {
              data[date] = slotData[date];
            })
        );
        await Promise.all(promises);
      } else {
        const response = await fetch(`${window.baseDomain}restaurants/slots/${window.restaurantNumber}/${startOfMonth}/${endOfMonth}/${labelId}`);
        data = await response.json();
      }

      console.log(data);

      setSlotData((prevSlotData) => ({ ...prevSlotData, ...data }));
      setCachedSlotData((prevCachedData) => ({ ...prevCachedData, [cacheKey]: data }));
    } catch (error) {
      console.error('Error fetching slot data:', error);
    } finally {
      setIsFetchingSlots(false);
    }
  };

  const fetchLabels = async () => {
    try {
      const response = await fetch(`${window.baseDomain}tables/label/${window.restaurantNumber}`);
      const data = await response.json();
      const allLabels = data.map(label => ({
        value: label.id,
        label: label.display_description,
        max_capacity: label.max_capacity
      }));

      let filteredLabels = [];

      if (window.validKey && window.eventPage) {
        filteredLabels = allLabels.filter(label => label.label.endsWith(window.eventName));
      } else {
        filteredLabels = allLabels.filter(label => !label.label.endsWith(window.eventName));
      }

      setLabels(filteredLabels);

      if (filteredLabels.length > 0) {
        const defaultLabel = filteredLabels.reduce((max, label) => (label.max_capacity > max.max_capacity ? label : max), filteredLabels[0]);
        setSelectedLabel(defaultLabel.value);
        setSelectedLabelId(defaultLabel.value);

        console.log("Fetching");
        fetchSlotData(new Date().getFullYear(), new Date().getMonth() + 1, defaultLabel.value);
        if (filteredLabels.length === 1) {
          setGuests(Math.min(guests, defaultLabel.max_capacity));
        }
      }
    } catch (error) {
      console.error('Error fetching labels:', error);
    }
  };

  let running = 0;
  useEffect(() => {
    if (running === 0) {
      fetchLabels();
      running = 1;
    }
  }, []);

  useEffect(() => {
    const today = new Date();
    if (selectedLabelId != null && running === 0) {
      fetchSlotData(today.getFullYear(), today.getMonth() + 1, selectedLabelId);
    }
  }, [selectedLabelId]);

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setSelectedSlot(null);
  };

  const handleGuestChange = (event) => {
    setGuests(Number(event.target.value));
  };

  const handleLanguageChange = (selectedOption) => {
    setLanguage(selectedOption.value);
  };

  const handleLabelChange = async (selectedOption) => {
    setSelectedLabel(selectedOption.value);
    setSelectedLabelId(selectedOption.value);

    const selectedLabel = labels.find(label => label.value === selectedOption.value);
    if (selectedLabel && guests > selectedLabel.max_capacity) {
      setGuests(selectedLabel.max_capacity);
    }
  };

  const handleSlotSelect = (slot, isQuarterSlotValue) => {
    setSelectedSlot(slot);
    setIsQuarterSlot(isQuarterSlotValue);
  };

  const handleSubmit = async () => {
    const shiftDescription = localStorage.getItem('shiftDescription');
    const cleanMenu = selectedMenu ? selectedMenu.split('__rules')[0] : "";
  
    const reservationData = {
      start_time: selectedSlot !== null ? selectedSlot : null,
      date: format(selectedDate, 'yyyy-MM-dd'),
      guests,
      restaurant: window.restaurantNumber,
      phone,
      email,
      first_name: firstName,
      last_name: lastName,
      language: language,
      table_label: selectedLabelId,
      menu: cleanMenu,
      extra_info: extraInfo,
      timeslot_name: shiftDescription || (isQuarterSlot ? slotToQuarterTime(selectedSlot) + "u" : slotToTime(selectedSlot) + "u"),
      is_quarter: isQuarterSlot,
    };
  
    setIsLoading(true);
  
    try {
      const reservationPromise = fetch(window.baseDomain + 'reservations/create/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(reservationData)
      });
  
      const delayPromise = new Promise(resolve => setTimeout(resolve, 4500));
      const [response] = await Promise.all([reservationPromise, delayPromise]);
  
      if (response.ok) {
        navigate(`/success/${language}`, { state: { reservationData } });
      } else {
        const errorData = await response.json();
        navigate(`/failed/${language}`, { state: { errorData } });
      }
    } catch (error) {
      console.error('Error submitting reservation:', error);
      navigate(`/failed/${language}`, { state: { errorData: error.message } });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedSlot !== null) {
      setShowModal(true);
    }
  }, [selectedSlot]);

  const handleGuestsClick = () => {
    setShowModal(false);
    setTimeout(() => {
      guestsRef.current.focus();
      guestsRef.current.click();
    }, 300);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedSlot(null);
  };

  const clearSelectedSlot = () => {
    setSelectedSlot(null);
  };

  const backgroundImageStyle = window.background ? { backgroundImage: `url(${window.background})` } : {};

  const maxGuests = window.maxGuests || 8;
  const guestOptions = [];
  for (let i = 1; i <= maxGuests; i++) {
    guestOptions.push(<option key={i} value={i}>{language === 'NL' ? `${i} ${i > 1 ? 'personen' : 'persoon'}` : language === 'FR' ? `${i} personne${i > 1 ? 's' : ''}` : `${i} ${i > 1 ? 'people' : 'person'}`}</option>);
  }
  if (window.ownerPhone && window.maxGuests) {
    guestOptions.push(<option key={maxGuests + 1} value={maxGuests + 1}>{language === 'NL' ? `${maxGuests}+ personen` : language === 'FR' ? `${maxGuests}+ personnes` : `${maxGuests}+ people`}</option>);
  }

  const showAlert = guests > maxGuests && window.ownerPhone;

  return (
    <div>
      {isLoading ? (
        <LoadingPage language={language} />
      ) : (
    <Container className="reservation-container">
      <div className='fullscreen-bg-reservation' style={backgroundImageStyle}></div>
      <div className={`frame ${showAlert ? 'frame-alert' : ''}`}></div>

      <Row className="justify-content-md-center align-items-center">
        <Col xs={6} sm={9} md={6} lg={10} xl={6}>
          <h1>{window.title ? window.title : (language === 'NL' ? 'Reserveer Nu' : language === 'FR' ? 'Réservez Maintenant' : 'Reserve Now')}</h1>
        </Col>
        <Col xs={6} sm={3} md={2} lg={2} xl={2} className="language-selector-col">
          <Select
            options={languageOptions}
            defaultValue={languageOptions.find(option => option.value === language)}
            onChange={handleLanguageChange}
            className="language-selector"
          />
        </Col>
      </Row>

      {visibleNotifications.map((notification) => (
        <Row key={notification.id} className="justify-content-md-center align-items-center">
          <Col xs={12} sm={12} md={8} lg={12} xl={8}>
            <Alert variant="warning" className="mt-3">
              {notification.notification_text}
            </Alert>
          </Col>
        </Row>
      ))}

      <Row className="justify-content-md-center align-items-center">
        <Col xs={12} sm={12} md={8} lg={12} xl={8} className="language-selector-col">
          {showAlert && (
            <Alert variant="info" className="mt-3">
              {language === 'NL' ? (
                <>Voor reserveringen van meer dan {window.maxGuests} personen, gelieve ons telefonisch te contacteren op het nummer <a href={`tel:${window.ownerPhone}`}>{window.ownerPhone}</a>.</>
              ) : language === 'FR' ? (
                <>Pour les réservations de plus de {window.maxGuests} personnes, nous vous prions de bien vouloir nous contacter directement par téléphone. Vous pouvez nous joindre au <a href={`tel:${window.ownerPhone}`}>{window.ownerPhone}</a>.</>
              ) : (
                <>For reservations exceeding {window.maxGuests} people, we kindly ask you to get in touch with us directly by phone. You can reach us at <a href={`tel:${window.ownerPhone}`}>{window.ownerPhone}</a>.</>
              )}
            </Alert>
          )}
        </Col>
      </Row>

      <Row className="guests-row justify-content-md-center">
        <Col xs={12} sm={12} md={8} lg={12} xl={8}>
          <Form>
            <Form.Label htmlFor="formNumberOfGuests">
              {language === 'NL' ? 'Aantal Gasten' : language === 'FR' ? 'Nombre de Convives' : 'Number of Guests'}
            </Form.Label>
            <Form.Group controlId="formNumberOfGuests">
              <Form.Control
                as="select"
                value={guests}
                onChange={handleGuestChange}
                ref={guestsRef}
              >
                {guestOptions}
              </Form.Control>
            </Form.Group>
          </Form>
        </Col>
      </Row>

      {!showAlert && (
        <>
          {labels.length > 1 && (
            <Row className="selection-box mt-3 justify-content-md-center align-items-center">
              <Col xs={12} sm={12} md={8} lg={12} xl={8}>
                <Form.Label htmlFor="formReservationType">
                  {language === 'NL' ? 'Selecteer Zitplaats' : language === 'FR' ? 'Sélectionnez le Type de Réservation' : 'Select Reservation Type'}
                </Form.Label>
                <Select
                  options={labels}
                  value={labels.find(option => option.value === selectedLabel)}
                  onChange={handleLabelChange}
                  className="label-selector"
                />
              </Col>
            </Row>
          )}

          <Row className="row justify-content-md-center align-items-start">
            <Col xs={12} sm={12} md={8} lg={6} xl={4}>
              <Calendar
                selectedDate={selectedDate}
                handleDateSelect={handleDateSelect}
                slotData={slotData}
                guests={guests}
                language={language}
                fetchSlotData={(year, month) => fetchSlotData(year, month, selectedLabelId)}
                allowedDates={window.allDates}
                clearSelectedSlot={clearSelectedSlot}
              />
            </Col>
            <Col xs={12} sm={12} md={8} lg={6} xl={4}>
              <Timeslot
                selectedDate={selectedDate}
                slotData={slotData}
                guests={guests}
                setSelectedSlot={handleSlotSelect}
                language={language}
                clearSelectedSlot={clearSelectedSlot}
              />
            </Col>
          </Row>

          <ReservationModal
            showModal={showModal}
            setShowModal={handleModalClose}
            guests={guests}
            selectedDate={selectedDate}
            selectedSlot={selectedSlot}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            email={email}
            setEmail={setEmail}
            phone={phone}
            setPhone={setPhone}
            handleSubmit={handleSubmit}
            handleGuestsClick={handleGuestsClick}
            language={language}
            selectedMenu={selectedMenu}
            setSelectedMenu={setSelectedMenu}
            extraInfo={extraInfo}
            setExtraInfo={setExtraInfo}
            isQuarterSlot={isQuarterSlot}
            agreedTerms={agreedTerms}
            setAgreedTerms={setAgreedTerms}
          />

          <ExplanationModal
            show={showExplanationModal}
            handleClose={() => setShowExplanationModal(false)}
            language={language}
          />
        </>
      )}

      {!showAlert && (
        <div className="mateza-container">
          <p>
            <a className="mateza-link" href="https://mateza.be" target="_blank">Mateza Booking 3.0</a>
            <MatezaTooltip language={language} />
          </p>
        </div>
      )}

      <div className="help-icon" onClick={() => setShowExplanationModal(true)}>
        ?
      </div>
    </Container>)}
    </div>
  );
};

export default Reservation;
