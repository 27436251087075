import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ExplanationModal = ({ show, handleClose, language }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {language === 'NL' ? 'Hoe maakt u een reservatie?' : language === 'FR' ? 'Comment faire une réservation?' : 'How to make a reservation?'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ol className="steps-list">
          {language === 'NL' ? (
            <>
              <li><strong>Open</strong> de reservatiepagina.</li>
              <li><strong>Selecteer</strong> het aantal personen bovenaan in het dropdown-menu.</li>
              <li><strong>Kies</strong> een datum door op de kalender te klikken. Beschikbare datums worden gemarkeerd in het <strong>blauw</strong>.</li>
              <li>Selecteer <strong>Ochtend</strong>, <strong>Middag</strong>, <strong>Avond</strong> of <strong>Nacht</strong> en klik daarna op het gewenste tijdslot.</li>
              <li>Vul uw <strong>gegevens</strong> in: voornaam, achternaam, e-mail en telefoonnummer.</li>
              <li><strong>Controleer</strong> uw gegevens en klik op de knop <strong>"Bevestigen"</strong> om uw reservatie in te dienen.</li>
              <li>Als de reservatie succesvol is, word u doorgestuurd naar een <strong>bevestigingspagina</strong> en ontvangt u een <strong>bevestigingsmail</strong>.</li>
            </>
          ) : language === 'FR' ? (
            <>
              <li><strong>Ouvrez</strong> la page de réservation.</li>
              <li><strong>Sélectionnez</strong> le nombre de personnes en haut du menu déroulant.</li>
              <li><strong>Choisissez</strong> une date en cliquant sur le calendrier. Les dates disponibles sont marquées en <strong>bleu</strong>.</li>
              <li>Sélectionnez <strong>Matin</strong>, <strong>Après-midi</strong>, <strong>Soir</strong> ou <strong>Nuit</strong> puis cliquez sur le créneau horaire souhaité.</li>
              <li>Remplissez vos <strong>détails</strong> : prénom, nom de famille, email et numéro de téléphone.</li>
              <li><strong>Vérifiez</strong> vos informations et cliquez sur le bouton <strong>"Confirmer"</strong> pour soumettre votre réservation.</li>
              <li>Si la réservation est réussie, vous serez redirigé vers une <strong>page de confirmation</strong> et recevrez un <strong>email de confirmation</strong>.</li>
            </>
          ) : (
            <>
              <li><strong>Open</strong> the reservation page.</li>
              <li><strong>Select</strong> the number of guests at the top in the dropdown menu.</li>
              <li><strong>Choose</strong> a date by clicking on the calendar. Available dates are marked in <strong>blue</strong>.</li>
              <li>Select <strong>Morning</strong>, <strong>Afternoon</strong>, <strong>Evening</strong>, or <strong>Night</strong> and then click on the desired timeslot.</li>
              <li>Fill in your <strong>details</strong>: first name, last name, email, and phone number.</li>
              <li><strong>Check</strong> your details and click on the <strong>"Confirm"</strong> button to submit your reservation.</li>
              <li>If the reservation is successful, you will be redirected to a <strong>confirmation page</strong> and receive a <strong>confirmation email</strong>.</li>
            </>
          )}
        </ol>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {language === 'NL' ? 'Sluiten' : language === 'FR' ? 'Fermer' : 'Close'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExplanationModal;
